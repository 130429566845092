.banner-carousel {
  margin-top: 20px;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  position: relative; /* Ajuste a altura conforme necessário */
  max-height: 820px;
}

.carousel-slide {
  width: 100%;
  height: 10%;
  display: none;
  transition: opacity 1s ease-in-out;
}

.carousel-slide.active {
  display: block;
  opacity: 1;
}

.carousel-slide img {
  width: 100%;
}
