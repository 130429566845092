.contact {
    min-height: 800px;
}

.contact h3 {
    margin-top: 20px;
    background-color: #28292B;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    min-height: 200px;
    font-weight: 900;
}

.contact-container {
    margin: 80px auto;
    max-width: 1000px;
    gap: 40px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 300px;
    justify-content: center;
    align-items: center;
}

.contact-block {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.contact-block img {
    max-width: 50px;
}

.contact-block h4 {
    margin: 0;
}

.contact-block p {
    margin-top: 7px;
}