.cards {
    background-color: #fff;
    max-width: 780px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 300px;
    margin: 26px auto;
}

.card {
    margin: 0 auto;
    display: flex;
    padding: 20px;
    max-width: 250px;
    flex-direction: column;
}