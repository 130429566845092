.box-top {
  height: 86px;
}

header {
  box-shadow: 3px 0px 9px 1px black;
  position: fixed; /* Recomendado para headers fixos */
    top: 0;
    left: 0;
    width: 100%; /* Ajuste a largura conforme necessário */
    background-color: #ffffff; /* Cor de fundo do header */
    z-index: 1000; /* Valor alto para garantir que fique acima de outros elementos */
    /* Outros estilos para o seu header */
  }

.icon img {
    max-width: 90px;

}

.nav {
    background-color: #113B65;
    color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: .2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: .5rem;
    list-style: none;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
}

.link {
  transition: .3s ease;
  margin-right: 30px;
  font-weight: 500;
  font-size: 1.2rem;
}

.link p {
  transition: .3s ease;
  color: #fff;
}

.link p:hover {
  color: #2D2C2E;
}

.hamburguer {
    display: none;
    cursor: pointer;
    transition: .3s all;
  }
  
  .hamburguer:hover {
    transform: scale(1.05);
  }
  
  .line-hamburguer {
    width: 30px;
    height: 3px;
    margin-top: 7px;
    background-color: #fff;
  }
  
  .active .line-hamburguer:nth-child(1) {
    transform: rotate(45deg) translate(3px, 3px);
  }
  
  .active .line-hamburguer:nth-child(2) {
    display: none;
  }
  
  .active .line-hamburguer:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -3px);
  }
  
  
.header-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media(max-width: 720px) {
  .box-top {
    height: 60px;
  }


    .hamburguer {
        display: block;
        float: right;
        padding: 15px;
    }

    .nav {
        display: block;
        padding: 0;
    }

    .nav ul {
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease; /* Adicione uma transição na propriedade max-height */
      }
      
    .nav.active ul {
        max-height: 400px; /* Ajuste a altura máxima conforme necessário */
        overflow: auto; /* Adicione uma barra de rolagem se necessário */
      }

    .icon img {
      width: 65px;
    }

    ul .link  {
      font-size: 17px;
        margin: 0;
        display: inline-block;
        text-align: center;
    }

    .link p {
      padding: 20px;
      color: #fff;
      transition: .3s ease;
    }

    .link:hover p {
      color: #2D2C2E;
    }

    .link:hover {
      background-color: #fff;
    }

}
