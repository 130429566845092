* {
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    color: #2D2C2E;
}

body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

img {
    width: 100%;
}

.servicos-bg {
    background-color: #28292B;
}

footer {
    border-top: 1px solid #fff;
    background-color: #28292B;
}

footer p {
    text-align: center;
    color: #fff;
    padding: 30px;
}

.whatsapp-link {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    transition: .3s ease;
}

.whatsapp-link:hover {
    transform: scale(1.07);
}

.fa-whatsapp {
    margin-top: 16px;
    color: #fff;
}