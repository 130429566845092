

h3 {
    font-size: 2.5rem;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 40px 0 0 0;
    color: #fff;
}

h4 {
    text-align: center;
    font-size: 1.8em;
    padding: 0;
    margin-top: 15px;
}

img {
    max-width: 100%;
}

.icon-img {
    max-width: 50px;
    display: block;
    margin: 0 auto;
}

.item-img {
    margin-top: 25px;
    justify-content: center;
    display: flex;
    gap: 2rem;
    flex: 1 1 400px;
    flex-wrap: wrap;
}

.item-img img {
    width: 350px;
    height: 300px;
    border-radius: 5px;
}

.p-top {
    margin: 20px auto;
    max-width: 600px;
    padding: 10px;
    color: #fff;
    font-size: 17px;
}

p {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.depoimentos {
    min-height: 600px;
}

.p-h3, .depoimentos p {
    padding: 20px;
    color: #fff;
    font-size: 14px;
}

.div-imgs {
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
}

.slider {
    max-width: 1000px;
    margin: 20px auto;

}

.div-imgs img {
    border-radius: 10px;
    margin: 0 auto;
}

div:has(> .info-google) {
    display: flex;
    justify-content: center;
}

.slick-next, .slick-prev {
    display: none !important;
}

.info-google {
    max-width: 300px;
    height: 360px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 3px 4px 5px rgba(0, 0, 0, 0.178);
    display: block;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.google-icon {
    max-width: 50px;
    display: block;
    margin: 0 auto 30px auto;
}

.profile-icon {
    max-width: 65px;
    display: block;
    margin: 0 auto 10px auto;
}

.info-google h4 {
    margin: 0;
    font-size: 20px;
    color: black;
}

.info-google p {
    padding: 10px;
    font-size: 12px;
    color: black;
}

.stars {
    max-width: 200px;
    display: block;
    margin: auto;
}

.slick-dots {
    display: none !important;
}

@media(max-width: 720px) {
   h3 {
    font-size: 30px;
   } 

   .item-img img {
    width: 150px;
    height: 150px;
   }

   .p-top {
    font-size: 14px;
   }
}

@media(max-width: 400px) {

    .item-img img {
     width: 140px;
     height: 110px;
    }
 }