.services {
    min-height: 800px;
}

.services h3 {
    margin-top: 20px;
    color: #fff;
    padding: 5px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #28292B;
    font-weight: 900;
}

.images-flex {
    margin: 20px auto;
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    flex: 1 1 400px;
    justify-content: space-around;
    gap: 10px;
}

.images-flex img {
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    padding: 5px;
    max-width: 340px;
}

.services a {
    display: block;
    background-color: #28292B;
    max-width: 200px;
    padding: 20px 30px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 30px;
    margin: 0 auto 40px auto;
    text-align: center;
    color: #fff;
    transition: .3s ease;
}

.services a:hover {
    transform: scale(1.07);
}